import React, {useState} from "react";
import {
    Box,
    Button,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    TextField,
    Typography,
    useTheme
} from "@mui/material";
import {useParams} from "react-router-dom";
import {motion, useMotionValue} from "framer-motion";
import wordCheck from "../../functions/wordCheck";
import SuccessCheck from "../SuccessCheck";

export const AltroQuestion = ({sendAnswer, answered, success, sending, tipo, answer, elementId}) => {
    const {id} = useParams()
    const [word, setWord] = useState('')
    const [spazio, setSpazio] = useState(false)
    const theme = useTheme()

    let progress = useMotionValue(90)

    const handleSetWord = (event) => {
        setWord(event.target.value)
    }

    function capitalizeTheFirstLetterOfEachWord(words) {
        var separateWord = words.toLowerCase().split(' ');
        for (var i = 0; i < separateWord.length; i++) {
            separateWord[i] = separateWord[i].charAt(0).toUpperCase() +
                separateWord[i].substring(1);
        }
        return separateWord.join(' ');
    }

    const handleSendAnswer = () => {
        let w = capitalizeTheFirstLetterOfEachWord(word).trim()

        setSpazio(false)
        const parolaArray = word.split(' ')
        if (wordCheck(w)) {
            sendAnswer(w)
            setWord('')
        } else {
            setWord('')
        }

    }

    return(
        <Stack sx={{position:'relative'}} justifyContent={"start"} alignItems={"start"} style={{textAlign:'center', height:'100%'}} spacing={5}>
            {(!success) && <Box sx={{width: '100%', mt:5}}>
                <FormControl style={{width: '100%'}}>
                    <InputLabel id="demo-simple-select-label">Racconto di:</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={word}
                        label="Racconto di:"
                        onChange={handleSetWord}
                    >
                        <MenuItem value={'Bergamo Via Zanchi'}>Bergamo Via Zanchi</MenuItem>
                        <MenuItem value={'Cernusco'}>Cernusco</MenuItem>
                        <MenuItem value={'Saronno'}>Saronno</MenuItem>
                        <MenuItem value={'Lecco'}>Lecco</MenuItem>
                        <MenuItem value={'Torino'}>Torino</MenuItem>
                        <MenuItem value={'Verona'}>Verona</MenuItem>
                        <MenuItem value={'Roma'}>Roma</MenuItem>
                        <MenuItem value={'Reggio Emilia'}>Reggio Emilia</MenuItem>
                        <MenuItem value={'Padova'}>Padova</MenuItem>
                        <MenuItem value={'Brescia'}>Brescia</MenuItem>
                        <MenuItem value={'Bergamo Via Zanica'}>Bergamo Via Zanica</MenuItem>
                    </Select>
                </FormControl>
                <Button disabled={word.length<2} sx={{mt: 4}} variant={'wordcloud'} onClick={handleSendAnswer}>Invia</Button>
            </Box>}
            {(success || answered) &&
                <Box style={{width:'100%'}}>
                    <motion.div
                        initial={{ x: 0 }}
                        animate={{ x: 100 }}
                        style={{ x: progress }}
                        transition={{ duration: 0.8 }}
                    />
                    <SuccessCheck progress={progress}/>
                </Box>
            }
        </Stack>
    )
}