import React from "react";
import {Button, Grid, Typography} from "@mui/material";

const SentimentQuestion = ({tipo, sendAnswer, answer, theme}) => {
    console.log()
    return (
        <Grid sx={{position: 'relative'}} container justifyContent={"center"} alignItems={"center"}
              style={{textAlign: 'center', height: '100%', position:'relative'}} spacing={5}>
            <Grid item xs={6} sx={{aspectRatio:'1/1'}}>
                <Button onClick={() => sendAnswer('yes')} variant={'sentiment'} risposta={'daccordo'} style={{opacity: answer==='no' && '0.2', background: theme.colors[0], borderRadius:'50rem'}}>
                    <Typography variant={'h5'} fontWeight={'bold'} color={tipo === 'clickTo' ? '#263238' : 'black'}>Più</Typography>
                </Button>
            </Grid>
            <Grid item xs={6} sx={{aspectRatio:'1/1'}}>
                <Button onClick={() => sendAnswer('no')} variant={'sentiment'} risposta={'nonDaccordo'} style={{opacity: answer==='yes' && '0.2', background: theme.colors[1], borderRadius:'50rem'}}>
                    <Typography variant={'h5'} fontWeight={'bold'} color={'black'}>Meno</Typography>
                </Button>
            </Grid>
        </Grid>
    )
}

export default SentimentQuestion
