import React, {useEffect, useState} from "react";
import {
    Backdrop, Box,
    Button, capitalize,
    Card,
    CircularProgress, Collapse,
    Container,
    Snackbar,
    Stack, TextareaAutosize,
    TextField,
    Typography
} from "@mui/material";
import {useNavigate, useParams} from "react-router-dom";
import axios from "axios";
import {TOOLPRESS_API} from "../../config";


export default function ToolpressQuestion({elementPlaying}) {
    const {id} = useParams()
    const [fields, setFields] = useState([])
    let navigate = useNavigate()
    const [values, setValues] = useState({
        domanda: ''
    })
    const [loading, setLoading] = useState(true)
    const [success, setSuccess] = useState(false)
    const [sending, setSending] = useState(false)
    const [error, setError] = useState(false)
    const [open, setOpen] = useState(false)

    useEffect(() => {
        axios.get(`${TOOLPRESS_API}/conference/${elementPlaying.conferenza}/columns`)
            .then(response => {
                const obj = {}
                setFields(response.data)
                response.data.map(r => obj[r] = '')
                setValues({...values, ...obj})
                setLoading(false)
            })
            .catch(() => {

            })
    }, [])

    const handleChangeValue = (e, key) => {
        setValues({...values, [key]: e.target.value})
    }

    const postQuestions = async () => {
        setSending(true)
        await axios.post(`${TOOLPRESS_API}/question/${elementPlaying.conferenza}`, {question: values})
        setSending(false)
        setValues(prevState => ({...prevState, domanda: ''}))
    }


    const sendQuestion = (e) => {
        e.preventDefault()

        postQuestions()
            .then(() => {
                setSuccess(true)
                setOpen(true)
                setTimeout(() => setOpen(false),5000)
            })
            .catch(() => setError(true))

    }


    return(
        <Box>
            <Backdrop
                sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
                open={loading || sending}
            >
                <CircularProgress color="inherit"/>
            </Backdrop>
            <Container>
                <form style={{padding: '1rem 1rem', textAlign: 'left'}} onSubmit={sendQuestion}>
                    <Stack direction={"column"} spacing={4}>
                        {fields.map(f =>
                            <Box>
                                <TextField sx={{width: '100%'}} variant={"standard"}
                                           label={capitalize(f)}
                                           required
                                           value={values[f]} onChange={(e) => handleChangeValue(e, f)}
                                />
                            </Box>
                        )}
                        <Box sx={{width: '100%'}}>
                            <TextField
                                required
                                fullWidth
                                label={'Risposta'}
                                variant={"outlined"}
                                onChange={(e) => handleChangeValue(e, 'domanda')}
                                value={values['domanda']}
                                multiline
                                minRows={4}
                            />
                        </Box>
                        <Box sx={{textAlign: 'center'}}>
                            <Button sx={{my: 2, fontSize:'0.85rem'}} variant={"wordcloud"} type={"submit"}>
                                {success ? "Invia un'altra risposta" : 'Invia risposta'}
                            </Button>
                        </Box>
                    </Stack>
                </form>
                <Collapse in={open}>
                    <Typography>
                        Risposta inviata con successo
                    </Typography>
                </Collapse>
            </Container>
        </Box>
    )
}
