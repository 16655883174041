import React from "react";
import {List, ListItem, styled, Typography} from "@mui/material";

export default function QuizQuestion({risposte, corretta, answer, sendAnswer, poll}) {
    const RispostaStyled = styled(ListItem)({
        border: '2px solid #09abf2',
        borderRadius: '0.7rem',
        background:'#03234d',
        margin: '1rem 0',
        cursor: 'pointer',
    })

    const computeBkg = (r) => {
        if(!poll) {
            if (answer !== '') {
                if (corretta === r) {
                    return '#3A722D'
                } else if (answer === r  && answer !== corretta){
                    return '#DB0A16'
                }
            }
        } else {
            if(answer === r){
                return '#09abf2'
            }
        }

    }

    const Risposte = () => (
        risposte.map((r, i) => (
            <RispostaStyled key={i} style={{
                width: '100%',
                background: computeBkg(r),
                border: computeBkg(r) ? '2px solid '+ computeBkg(r) : '1px solid #09abf2',
                color: 'white',
            }} onClick={() => rispondi(r)}>
                <Typography variant={"h6"} fontWeight='400'>
                    {i + 1}. {r}
                </Typography>
            </RispostaStyled>
        ))
    )

    const rispondi = (r) => {
        if(poll) {
            sendAnswer(r)
        } else {
            if(answer === ''){

                sendAnswer(r)
            }
        }
    }

    return (
        <List>
            <Risposte/>
        </List>
    )
}
